import React from 'react'
import SEO from 'components/elements/SEO/SEO'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'
import { useStaticQuery, graphql } from 'gatsby'
import { useThemeContext } from 'context/ThemeContext'
import Img from 'gatsby-image'

const CustomersAndPartners = () => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
        nodes {
          id
          name
          childImageSharp {
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  function uppercase(string) {
    return string.toUpperCase().split(' ').join(' ')
  }
  const { isMobile } = useThemeContext()

  return (
    <>
      <SEO title="Clienti&amp;Partners" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <div className="cap closer">
        <h3 className="title">Clienti &amp; Partners</h3>
        <GridList cols={isMobile ? 1 : 3} cellHeight="auto" spacing={30} className="gridList">
          {data.images.nodes.map((image) => (
            <GridListTile className="single-partners" key={image.id}>
              <div className="partners-image">
                <Img fluid={image.childImageSharp.fluid} />
              </div>
              <Typography className="partners-info" component="p">
                {uppercase(image.name).replace(/-/g, ' ')}
              </Typography>
            </GridListTile>
          ))}
        </GridList>
      </div>
    </>
  )
}
export default CustomersAndPartners
